* {
  margin: 0;
  padding: 0;
}


#pp-nav li .active span,
.pp-slidesNav .active span {
  background: #FEC544 !important;
}



.sec2, .sec3 , .sec4, .sec5, .sec6, .sec7, .sec8 {
   background: rgb(0,6,38); 
   background: radial-gradient(circle, rgba(0,6,38,1) 0%, rgba(0,2,13,1) 50%, rgba(4,2,26,1) 100%); 
 
   /* background: url("./bg.jpg"); */
  /* background-size: cover; */
}

.sec1{
  background: url("./bg.jpg");
  background-size: cover;
  /* background: rgb(0,6,38); */
/* background: radial-gradient(circle, rgba(0,6,38,1) 0%, rgba(0,2,13,1) 50%, rgba(4,2,26,1) 100%); */
}
.sec9{
  background: url("./bg.jpg");
  background-size: cover;
}
/* ================================== */
canvas{
  display:block;
  vertical-align:bottom;
}

::-webkit-scrollbar{
  width: 5px;
}
::-webkit-scrollbar-track{
  background: rgb(0, 1, 7);
}
::-webkit-scrollbar-thumb{
  background: #FEC544;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover{
  background:#fab616;
}
/* ---- stats.js ---- */


/* ---- particles.js container ---- */



canvas{
  display:block;
  vertical-align:bottom;
}


/* ---- stats.js ---- */
/* 
.count-particles{
  position: absolute;
  z-index: 11111;
}
@media screen and (min-width: 280px) {
  .count-particles{
    top: 80%;
  }
.profileimg{
  width: 200px;
  height: 200px;
}
.nameh1{
  font-size: large;
}
}
@media screen and (min-width: 350px) {
  .count-particles{
    top: 80%;
  }
}
@media screen and (min-width: 450px) {
  .count-particles{
    top: 80%;
  }
} */


/* ---- particles.js container ---- */

#particles-js{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.adminclass{
  background-color: transparent;
  border: none;
}
/* =============================================== */
 ul li.active{
  color: #FEC544;
}
.modeldivsize{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
 border:1px solid #FEC544;
  box-shadow: 24;
  padding: 0;
  width:1000px
}
@media screen and (min-width: 280px) {
  .modeldivsize{
width: 280px;
  }
}
@media screen and (min-width: 380px) {
  .modeldivsize{
width: 380px;
  }
}
@media screen and (min-width: 480px) {
  .modeldivsize{
width: 480px;
  }
}
@media screen and (min-width: 580px) {
  .modeldivsize{
width: 580px;
  }
}
@media screen and (min-width: 680px) {
  .modeldivsize{
width: 680px;
  }
}
@media screen and (min-width: 780px) {
  .modeldivsize{
width: 780px;
  }
}
@media screen and (min-width: 1000px) {
  .modeldivsize{
width: 1000px;
  }
}
.boxxx{
  height: 44px;
  width: 44px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
  
}
.boxxx::before{
  content: "";
  background-image: conic-gradient(
      #ffb300 30deg,
      transparent 120deg
  );
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  animation: rotate 2s linear infinite;
 
}
.boxxx:hover{
  border: 1px solid white;
}

.boxxx::after{
  content: '▶';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 95%;
  background: #01010D;
  position: absolute;
  border-radius: 50%;
 
}

/*  */

  
@keyframes rotate {
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(-360deg);
  }}
